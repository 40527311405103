import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import AddSuspensionPoint from 'src/components/add-suspension-point/add-suspension-point';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const PlayerProfileAddSuspensionPointsPage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/players/profile">
      <PlayerProfileAddSuspensionPoints path="/:id" />
    </Router>
  );
};

interface PlayerProfileAddSuspensionPointsRouteProps extends RouteComponentProps {
  id?: string;
}

const PlayerProfileAddSuspensionPoints: React.FC<PlayerProfileAddSuspensionPointsRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Add suspension points" />
      <AddSuspensionPoint />
    </Layout>
  );
};

export default PlayerProfileAddSuspensionPointsPage;
